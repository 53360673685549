import React, {useEffect, useRef, createRef} from "react"
import {Link, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import SpiralGraph from '../components/SpiralGraph/spiralgraph'
import Newsletter from '../components/footer/newsletter'
import SVGCoinsStacked from '../images/coins-stacked.svg'
import SVGCoinsFalling from '../images/coins-falling.svg'
import SVGAlaska from '../images/alaska.svg'
import SVGGrant from '../images/grant.svg'
import gsap from 'gsap'
import { random } from "gsap/all"
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger); 

export default ({data}) => {
  // Falling coins animation
  const refCoin = useRef([])
  const refGrant = useRef([])
  const refGrants = useRef()
  for (let i = 0; i < 5; i++) {
    refCoin.current[i] = createRef()
  }
  for (let i = 0; i < 12; i++) {
    refGrant.current[i] = createRef()
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Coins
      function fall(coin) {
        let tl = gsap.timeline({repeat: 0, onComplete: () => { fall(coin)}, delay: random(0.5, 1.5)})
        tl.set(refCoin.current[coin].current, {top: 0, left: random(20, 80) + '%', scale: random(0.7, 1.1)})
        tl.to(refCoin.current[coin].current, {opacity: 1, duration: 0.5})
        tl.to(refCoin.current[coin].current, {top: '100%', duration: random(2, 4), delay: -0.5, ease: 'none'})
        tl.to(refCoin.current[coin].current, {opacity: 0, duration: 0.5, delay: -1})
      }
      fall(0)
      fall(1)
      fall(2)
      fall(3)
      fall(4)

    }
    //Grants
    let pW = refGrants.current.offsetWidth
    let pH = refGrants.current.offsetHeight
    const tl2 = gsap.timeline({
      yoyo: false,
      scrollTrigger: {
        trigger: '.grantGrid',
        start: 'top 40%',
        scrub: false,
      },
    })
    for (let i = 0; i < refGrant.current.length; i++) {
      tl2.set(refGrant.current[i].current, {opacity: 0.4, scale: 1})
      let x = (pW / 2) - refGrant.current[i].current.offsetLeft - refGrant.current[i].current.offsetWidth
      let y = (pH / 2) - refGrant.current[i].current.offsetTop - refGrant.current[i].current.offsetHeight
      tl2.from(refGrant.current[i].current, {
        left: x,
        top: y,
        scale: 2,
        opacity: 1,
        ease: 'back.out',
        stagger: true,
        duration: 0.6,
        delay: -0.4
      })
    }
    tl2.fromTo('.grant', {
      y: 0,
      opacity: 0.4
    },
    {
      scale: 1.05,
      // y: -1,
      opacity: 1,
      duration: 2,
      stagger: {
        yoyo: true,
        repeat: -1,
        each: 1,
        grid: 'auto',
        ease: 'none',
      },
      delay: -1
    })
    // Donations AOI Bar Graph
    gsap.to('.barAoI', {
      scrollTrigger: {
        trigger: '.tableAoI',
        start: 'top 60%'
      },
      width: function(index, target) {
        return `${target.dataset.w}%`
      },
      stagger: {
        amount: 1,
        delay: -0.4
      },
    })
    // Donations Communities Bar Graph
    gsap.to('.barComm', {
      scrollTrigger: {
        trigger: '.tableComms',
        start: 'top 60%'
      },
      width: function(index, target) {
        return `${target.dataset.w}%`
      },
      stagger: {
        amount: 1,
        delay: -0.4
      },
    })
    // Grants AOI Bar Graph
    gsap.to('.barAoI2', {
      scrollTrigger: {
        trigger: '.tableAoI2',
        start: 'top 60%'
      },
      width: function(index, target) {
        return `${target.dataset.w}%`
      },
      stagger: {
        amount: 1,
        delay: -0.4
      },
    })
    // Grants Communities Bar Graph
    gsap.to('.barComm2', {
      scrollTrigger: {
        trigger: '.tableComms2',
        start: 'top 60%'
      },
      width: function(index, target) {
        return `${target.dataset.w}%`
      },
      stagger: {
        amount: 1,
        delay: -0.4
      },
    })
  }, [])

  return (
    <Layout>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug} />
      <Hero
        mode="eop"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={ data.wordpressPage.featured_media ?
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid
        :
          null
        }
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
        box1={typeof data?.wordpressPage?.acf?.header_eop_box1 !== 'undefined' ? data.wordpressPage.acf.header_eop_box1 : ''}
        box2={typeof data?.wordpressPage?.acf?.header_eop_box2 !== 'undefined' ? data.wordpressPage.acf.header_eop_box2 : ''}
        box3={typeof data?.wordpressPage?.acf?.header_eop_box3 !== 'undefined' ? data.wordpressPage.acf.header_eop_box3 : ''}
      />
      <div className="partnersDonations">
        <Container>
          <div className="contentEditorBlock" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.box_1}}/>
        </Container>
      </div>
      <SpiralGraph
        id="scholarshipsRewarded"
        spiralTitle={data.wordpressPage.acf.spiral_default_title}
        spiralDescription={data.wordpressPage.acf.spiral_default_message}
        spiralCats={data.wordpressPage.acf.spiral}
      />
      <div id="donationsGiven" className="donationsGiven">
        <Container>
          <div className="x-grid">
            <div className="coinsFallingWrapper">
              <div className="coinsFalling">
                <img ref={refCoin.current[0]} src={SVGCoinsFalling} style={{opacity: 0}} />
                <img ref={refCoin.current[1]} src={SVGCoinsFalling} style={{opacity: 1}} />
                <img ref={refCoin.current[2]} src={SVGCoinsFalling} style={{opacity: 1}} />
                <img ref={refCoin.current[3]} src={SVGCoinsFalling} style={{opacity: 1}} />
                <img ref={refCoin.current[4]} src={SVGCoinsFalling} style={{opacity: 1}} />
              </div>
              <div>
                <img className="coinsStacked" src={SVGCoinsStacked} />
              </div>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.dg_content}} />
              <div className="aoi">
                <h2>Areas of Impact</h2>
                <table className="tableAoI">
                  <tbody>
                    {data.wordpressPage.acf.dg_aoi.map((area, i) => {
                      return (
                        <tr key={`aoi-key-${i}`}>
                          <td>{area.title}</td>
                          <td width="65%">
                            <div
                              className="barAoI"
                              style={{
                                width: 0,
                                backgroundColor: '#8AC640',
                                height: '20px'
                              }}
                              data-w={area.percentage}
                            >
                              <div>
                                <small>{area.percentage}%</small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="communities">
                <h2>Communities</h2>
                <table className="tableComms">
                  <tbody>
                    {data.wordpressPage.acf.dg_comms.map((area, i) => {
                      return (
                        <tr key={`comm-key-${i}`}>
                          <td>{area.title}</td>
                          <td width="65%">
                            <div
                              className="barComm"
                              style={{
                                width: 0,
                                backgroundColor: '#31489E',
                                height: '20px'
                              }}
                              data-w={area.percentage}
                            >
                              <div className="makeMeBlue">
                                <small>{area.percentage}%</small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Container>
        <Container style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
          <Link to="/donation-application/" className="button">
            Learn More About Donations
          </Link>
        </Container>
      </div>
      <div id="grantsSigned" className="grantsSigned">
        <Container>
        <div className="x-grid">
          <div>
            <div dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.gs_content}} />


            <div className="aoi">
                <h2>Areas of Impact</h2>
                <table className="tableAoI2">
                  <tbody>
                    {data.wordpressPage.acf.gs_aoi.map((area, i) => {
                      return (
                        <tr key={`aoi-key-${i}`}>
                          <td>{area.title}</td>
                          <td width="65%">
                            <div
                              className="barAoI2"
                              style={{
                                width: 0,
                                backgroundColor: '#8AC640',
                                height: '20px'
                              }}
                              data-w={area.percentage}
                            >
                              <div>
                                <small>{area.percentage}%</small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="communities">
                <h2>Communities</h2>
                <table className="tableComms2">
                  <tbody>
                    {data.wordpressPage.acf.gs_comms.map((area, i) => {
                      return (
                        <tr key={`comm-key-${i}`}>
                          <td>{area.title}</td>
                          <td width="65%">
                            <div
                              className="barComm2"
                              style={{
                                width: 0,
                                backgroundColor: '#31489E',
                                height: '20px'
                              }}
                              data-w={area.percentage}
                            >
                              <div>
                                <small>{area.percentage}%</small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>



          </div>
          <div className="alaskaGrants">
            <div style={{position: 'relative'}}>
              <img className="alaskaMap" src={SVGAlaska} />
              <div ref={refGrants} className="grantGrid">
                {refGrant.current.map((grant, i) => {
                  return (
                    <img key={`grant-img-key-${i}`} ref={refGrant.current[i]} className="grant" src={SVGGrant} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        </Container>
        <Container style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
          <Link to="/grant-recipients" className="button">
            Grant Recipients
          </Link>
        </Container>
      </div>
      <div className="partnersDonations">
        <Container>
          <div className="contentEditorBlock" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.box_3}}/>
        </Container>
      </div>
      <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query exploreImpactQuery {
  wordpressPage(wordpress_id: {eq: 133}) {
    slug
    content
    title
    acf {
      box_1
      spiral_default_title
      spiral_default_message
      spiral {
        spiral_title
        spiral_description
        spiral_percentage
        spiral_breakdown
      }
      dg_content
      dg_aoi {
        title
        percentage
      }
      dg_comms {
        title
        percentage
      }
      gs_content
      gs_aoi {
        title
        percentage
      }
      gs_comms {
        title
        percentage
      }
      box_3
      header_content
      header_text
      header_button {
        url
        title
        target
      }
      header_eop_box1 {
        eop_hdr_amnt
        eop_hdr_title
        eop_hdr_description
        eop_hdr_anchor
      }
      header_eop_box2 {
        eop_hdr_amnt
        eop_hdr_title
        eop_hdr_description
        eop_hdr_anchor
      }
      header_eop_box3 {
        eop_hdr_amnt
        eop_hdr_title
        eop_hdr_description
        eop_hdr_anchor
      }
    }
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`
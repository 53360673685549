import React, { useEffect, useRef, useState } from 'react'
import styles from './spiralgraph.module.scss';
import Container from '../container'
import { Link } from 'gatsby'
import gsap from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import SVGPiggy from '../../images/piggy-bank.inline.svg'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger); 

gsap.registerPlugin( MotionPathPlugin )

const graphData = [
  { 
    title: 'High School Tech',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    percentage: 30,
  },
  { 
    title: 'Cooking',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute  velit esse cillum dolore eu fugiat nulla pariatur.',
    percentage: 23,
  },
  { 
    title: 'Art',
    description: 'Lorem magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    percentage: 19,
  },
  { 
    title: 'Civic',
    description: 'Lorem ipsum dolor sit amet, consectetur incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    percentage: 10,
  },
  { 
    title: 'Sports',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    percentage: 8,
  },
  { 
    title: 'Education',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.',
    percentage: 5,
  },
  { 
    title: 'Senior',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    percentage: 15,
  },
]
const SpiralGraph = ({id, spiralTitle, spiralDescription, spiralCats}) => {
  const bubblePath = useRef()
  const [ title, setTitle ] = useState('')
  const [ description, setDescription ] = useState('')
  const spiralBubbs = () => {
    const bubbles = document.querySelectorAll('.bubble')
    gsap.to( bubbles, {
      scrollTrigger: {
        trigger: '#spiralPiggy',
        start: 'top 40%'
      },
      motionPath: {
        path: bubblePath.current,
        align: bubblePath.current,
        start: 'end',
        offsetX: -30,
        offsetY: -30,
        start: 0,
        end: gsap.utils.distribute({
          base:0,
          amount: 1,
        }),
        from: 'start',
      }
    })
  }
  useEffect(() => {
    spiralBubbs()
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', spiralBubbs)
    }
  }, [])
  return (
    <div className={styles.spiralGraph} id={id}>
      <Container>
        <div className={styles.left}>
          <h2>{spiralTitle}</h2>
          <div dangerouslySetInnerHTML={{ __html: spiralDescription}} />
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: description}} />
        </div>
        <div className={styles.right} id="spiralPiggy">
          <SVGPiggy className={styles.piggy} />
          <svg className={styles.bubblePathSVG} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 236">
            <path ref={bubblePath} style={{ strokeWidth: 0, stroke: 'white', fill: 'transparent' }} d="M272,146c0-96.9-56.8-144-152.4-132.6S17.8,102.6,27.1,142.9c14.9,64.6,114.9,84.6,164.3,39.6"/>
          </svg>
          {
            spiralCats.map((node, i) => {
              return (
                <div 
                  key={`bubble-key-${i}`}
                  className={`${styles.bubble} bubble${node.spiral_breakdown === 'community' ? ` ${styles.community}` : ''}`} 
                  style={{ width: ((node.spiral_percentage * 2) + 5) + 'px', height: ((node.spiral_percentage * 2) + 5) + 'px' }}
                  onMouseEnter={() => {
                    setTitle(node.spiral_title)
                    setDescription(node.spiral_description)
                  }}
                  role="button"
                  tabIndex={0}>
                  <span>{node.spiral_percentage + '%'}</span>
                </div>
              )
            })
          }
          <div className={styles.legend}>
            <div className={styles.legendAoc}>
              <div></div>
              <div><small>Areas of Impact</small></div>
            </div>
            <div className={styles.legendComms}>
              <div></div>
              <div><small>Communities</small></div>
            </div>
          </div>
        </div>
      </Container>
      <Container style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
        <Link to="/recipients" className="button">
          Scholarship Recipients
        </Link>
      </Container>
    </div>
  )
}

export default SpiralGraph